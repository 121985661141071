import * as React from "react";
import Box from "@mui/material/Box";
import MuiLinearProgressBar from "./common/MuiLinearProgressBar";
import PropTypes from "prop-types";

export default function LinearProgressWithValueLabel({ progValue, isLandscape }) {
  const [progress, setProgress] = React.useState(progValue ? progValue : 0);

  React.useEffect(() => {
    if (progress < 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 96 ? 96 : prevProgress + 3
        );
      }, 3000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <MuiLinearProgressBar value={progress} isLandscape={isLandscape} />
    </Box>
  );
}

LinearProgressWithValueLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
