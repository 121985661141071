import { createContext, useContext, useState } from "react";

export const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const [consumerKey, setConsumerKey] = useState(
    "3MVG9T992fY2Y4vvTe6QCBsdz0KC8Qz5CaQrVP7mg621keiWtvXsi4aLDKo6H0o2g1xCUtzwz6uOVVYGYawBc"
  );
  const [consumerSecret, setConsumerSecret] = useState(
    "6059A0BBF0617548CA833AE0503DA27AFFCBF165580D91A75D59C1141FB6BB10"
  );
  return (
    <AuthContext.Provider
      value={{ consumerKey, setConsumerKey, consumerSecret, setConsumerSecret }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
