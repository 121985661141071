const GUIDANCE = Object.freeze({
    INVERSE:"INVERSE",
    FREE:"FREE",
    NO_OPTIONS:"NO_OPTIONS",
    OPTIONS:"OPTIONS",
    PARALLEL:"PARALLEL",
    RECTANGLE:"RECTANGLE",
    PRODUCT:"PRODUCT",
    SIDEWALL:"SIDEWALL"
})
export default GUIDANCE;