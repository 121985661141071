import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, useMediaQuery } from "@mui/material";

import { useStyles } from "./AppStyle";
import MuiDialog from "./common/MuiDialog";
import confirm_btn from "../assets/confirm_btn.png";
import retake_btn from "../assets/retake_btn.png";
import LinearProgressWithValueLabel from "./LinearProgressBar";
import preprocessImage from "../utility/preProcessImage";

import { Link } from "react-router-dom";

const ConfirmModal = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    open,
    image,
    displayImage,
    isButtonLoading,
    imageResponse,
    type,
    isButtonDisabled,
    canvas,
    width,
    height
  } = props;

  const isLandscape = useMediaQuery('(orientation: landscape)')


  const imageRef = useRef(null);
  useEffect(() => {
    const image = imageRef.current;
    // enhance(15, 0);
  }, [imageRef.current]);

  console.log(width, height, " image dim")
  console.log(type, " product type")
  return (
    <>
      <MuiDialog handleClose={handleClose} open={open} title="Damage" isLandscape={isLandscape}>
        <img
          ref={imageRef}
          id="captured-image"
          src={image}
          alt="Clicked"
          style={isLandscape && type === "product" || isLandscape && type === "Tire_Identification" || isLandscape && type.toUpperCase() === "TIRE_SERIAL_NUMBER" ? {
            width: "70vh",
            height: "25vh",
            // marginTop: "10px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "0",
            marginTop: "1%"
          } : (!isLandscape && type === "product" || !isLandscape && type === "Tire_Identification" || !isLandscape && type.toUpperCase() === "TIRE_SERIAL_NUMBER") && window.innerWidth >= 800 ? {
            width: "45vh",
            height: "18vh",
            marginTop: "10px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",

          } : (!isLandscape && type === "product" || !isLandscape && type === "Tire_Identification" || !isLandscape && type.toUpperCase() === "TIRE_SERIAL_NUMBER") && window.innerWidth < 800 ? {
            width: "25vh",
            height: "11vh",
            marginTop: "10px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }
            // : !isLandscape && width > 800 ? {
            //   marginTop: "10px",
            //   display: "block",
            //   marginLeft: "auto",
            //   marginRight: "auto",
            //   width: "40vh",
            //   height: "16vh"
            // } 
            : isLandscape ? {
              width: "80vh",
              height: "25vh",
              // marginTop: "5px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "0%",
              marginTop: "1%"

            } : {
              width: "30vh",
              height: "30vh",
              marginTop: "10px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
        />
        {type !== "product" && (
          <>
            {isButtonLoading && !imageResponse && (
              <LinearProgressWithValueLabel isLandscape={isLandscape}
                progValue={0}
              ></LinearProgressWithValueLabel>
            )}

            {imageResponse && !isButtonLoading && (
              <LinearProgressWithValueLabel isLandscape={isLandscape}
                progValue={100}
              ></LinearProgressWithValueLabel>
            )}
          </>
        )}
        <div
          style={isLandscape ? {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "1%"
          } : {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            className={classes.retryButton}
            color="primary"
            variant="outlined"
            onClick={props.handleRetry}
            disabled={props.isButtonDisabled ? true : false}
          >
            <img src={retake_btn} alt="Retry" />
          </Button>
          {type === "product" ? (
            <>
              <Button
                className={classes.confirmButton}
                variant="contained"
                color={imageResponse ? "success" : "primary"}
                sx={
                  imageResponse
                    ? {
                      pointerEvents: "none",
                    }
                    : { pointerEvents: "auto" }
                }
                onClick={props.submitImageToAI}
                disabled={!imageResponse && props.isButtonDisabled}
              >
                {isButtonLoading && <CircularProgress size={14} />}
                {!isButtonLoading && (
                  <>
                    {" "}
                    <img src={confirm_btn} alt="Confirm" />
                  </>
                )}
              </Button>
            </>
          ) : (
            <>
              {type === "Damage" ? (
                <>
                  <Button
                    className={classes.confirmButton}
                    variant="contained"
                    color={imageResponse ? "success" : "primary"}
                    sx={
                      imageResponse
                        ? {
                          pointerEvents: "none",
                        }
                        : { pointerEvents: "auto" }
                    }
                    onClick={props.handleDamageImage}
                    disabled={!imageResponse && props.isButtonDisabled}
                  >
                    {isButtonLoading && <CircularProgress size={14} />}
                    {!isButtonLoading && (
                      <>
                        <img src={confirm_btn} alt="Confirm" />
                      </>
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={classes.confirmButton}
                    variant="contained"
                    color={imageResponse ? "success" : "primary"}
                    sx={
                      imageResponse
                        ? {
                          pointerEvents: "none",
                        }
                        : { pointerEvents: "auto" }
                    }
                    onClick={() => props.handleDotSidewallImage(false)}
                    disabled={!imageResponse && props.isButtonDisabled}
                  >
                    {isButtonLoading && <CircularProgress size={14} />}
                    {!isButtonLoading && (
                      <>
                        {" "}
                        <img src={confirm_btn} alt="Confirm" />
                      </>
                    )}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
        <div></div>
      </MuiDialog>
    </>
  );
};

export default ConfirmModal;
