import { Box } from "@mui/material";
import React from "react";
import unauthorised from "../assets/unauthorised.png";
export default function UnauthorisedPage() {
  return (
    <Box
      sx={{
        overflow:"hidden",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ccc",
      }}
    >
      <Box
        sx={{
          width: "80%",
          height: "60%",
          borderRadius: "8px",
          backgroundColor: "#f2f2f2",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "50%",
          }}
          src={unauthorised}
          alt="Unauthorised Access"
        />
      </Box>
    </Box>
  );
}
