const CLI_TYPES = Object.freeze({

    DAMAGE: "DAMAGE",
  
    DAMAGE_OPTIONAL_1: "DAMAGE_OPTIONAL_1",
  
    DAMAGE_OPTIONAL_2: "DAMAGE_OPTIONAL_2",
  
  });
  
  export default CLI_TYPES;