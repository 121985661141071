import { useStyles } from "./AppStyle";
import { TextField, Typography } from "@material-ui/core";
import { Button, useMediaQuery } from "@mui/material";
const ClickImageButton = (props) => {
  const isLgLandscape = useMediaQuery('(orientation: landscape) and (min-width:920px)')
  const isMdLandscape = useMediaQuery('(orientation: landscape) and (min-width:690px)  and (max-width:919px)')

  const isSmLandscape = useMediaQuery('(orientation: landscape) and (max-width:689px)')
  console.log("isMdLandscape", isMdLandscape)
  // console.log("isSmLandscape", isSmLandscape)
  const classes = useStyles();
  return (
    <div
      className={isLgLandscape || isMdLandscape || isSmLandscape ? classes.cameraButtonLandscape : classes.cameraButton} >
      <Button
        className={isLgLandscape || isMdLandscape || isSmLandscape ? classes.circleButtonLandscape : classes.circleButton}
        color="primary"
        variant="contained"
        onClick={props.onClick}
      >
        {/* Click a photo */}
      </Button>
    </div >
  );
};

export default ClickImageButton;
