import axios from "axios";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error?.response?.status >= 400 && error?.response?.status < 500;
    if(!expectedError){
        // toast.error("An Unxpected error occured");

    }
    return Promise.reject(error)
});
export default{
    get:axios.get,
    post:axios.post,
    
}