import { CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { isExpired } from "react-jwt";
import { useParams } from "react-router-dom";
import {
  addDamageImageData,
  addDotSidewallImageData,
  verifyRequestService,
} from "../service/cliPageService";
import { isAndroid, isBrowser } from "react-device-detect";
import {
  addDotDataForAndroid,
  addDotDataForIos,
  getAuthToken,
  sendEditedDOT,
  sendImageToAI,
} from "../service/dotService";

import { decrypt, getDateTime, getTime } from "../utility/utilityFunc";
import ConfirmModal from "./ConfirmModal";
import EditDotModal from "./EditDotModal";
import Header from "./Header";
import WebCam from "./WebCam";
import UnauthorisedPage from "./UnauthorisedPage";
import background from "../assets/Background.png";
import CLI_TYPES from "../constants/cliTypes";
import {
  fetchAzureBearerToken,
  uploadImageToAzureService,
} from "../service/imageStorageService";
import CryptoJS from "crypto-js";
import { useMediaQuery } from "@material-ui/core";

export default function DotGuidanceApp() {
  let { info } = useParams();
  const [encodedInfo, setEncodedInfo] = useState({
    symbolname: "",
    userid: "",
    type: "",
    caseid: "",
    isPortal: false,
    isSandBox: false,
  });
  const [image, setImage] = useState(null);
  const [displayImage, setDisplayImage] = useState()
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [accessToken, setAccessToken] = useState();
  const [azureToken, setAzureToken] = useState();
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [dot, setDot] = useState("");
  const [dotRes, setDotRes] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [error, setError] = useState("");
  const [imageResponse, setImageResponse] = useState(false);
  const [isTimerUp, setIsTimerUp] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const [imgWidth, setImgWidth] = useState("")
  const [imgHeight, setImgHeight] = useState("")
  const [appHeight, setAppHeight] = useState("")
  const isLandscape = useMediaQuery('(orientation: landscape)')

  useEffect(() => {
    setTimestamp(getDateTime(new Date()));
    console.log(getTime(new Date()), "app launched")
  }, []);
  useEffect(() => {

    console.log("in useffect")
    setAppHeight(window.innerHeight)
  }, [isLandscape])
  useEffect(() => {
    if (image) {
      setOpenConfirmDialog(true);
    }
  }, [image]);
  useEffect(() => {
    if (info) {
      try {
        if (info !== "ImageRecognition") {
          localStorage.setItem("info", JSON.stringify(info));
        }
        getUserInfo(info);
      } catch (error) {
        // setIsLoading(false)
        return <>Not Authorised</>;
      }
    }
  }, [info]);
  const getUserInfo = (info) => {
    try {
      let decodedInfo = JSON.parse(atob(info));
      console.log("decodedInfo", decodedInfo);
      let data = {};
      if (!!decodedInfo?.caseLineItemNumber) {
        console.log(
          "Type info",
          decodedInfo?.cliphototype.toUpperCase() === CLI_TYPES.DAMAGE ||
            decodedInfo?.cliphototype.toUpperCase() ===
            CLI_TYPES.DAMAGE_OPTIONAL_1 ||
            decodedInfo?.cliphototype.toUpperCase() ===
            CLI_TYPES.DAMAGE_OPTIONAL_2
            ? "Damage"
            : decodedInfo?.cliphototype
        );
        data = {
          isPortal: decodedInfo?.isPortal,
          symbolname: decodedInfo?.symbolname,
          userid: decodedInfo?.userId,
          type:
            decodedInfo?.cliphototype.toUpperCase() === CLI_TYPES.DAMAGE ||
              decodedInfo?.cliphototype.toUpperCase() ===
              CLI_TYPES.DAMAGE_OPTIONAL_1 ||
              decodedInfo?.cliphototype.toUpperCase() ===
              CLI_TYPES.DAMAGE_OPTIONAL_2
              ? "Damage"
              : decodedInfo?.cliphototype,
          caseid: decodedInfo?.caseLineItemNumber,
          token: decrypt(decodedInfo?.token),
          isSandBox: decodedInfo?.isSandbox,
          deviceType: isAndroid || isBrowser ? "Android" : "IOS",
        };
      } else {
        data = {
          isPortal: decodedInfo?.isPortal,
          userid: decodedInfo?.userId,
          caseid: decodedInfo?.caseId,
          symbolname: decodedInfo?.symbolname,
          token: decrypt(decodedInfo?.token),
          isSandBox: decodedInfo?.isSandbox,
          deviceType: decodedInfo?.devicetype,
          type: "product",
        };
      }
      console.log("+++++Data", data);
      console.log(decrypt(decodedInfo?.token), "decrypt(decodedInfo?.token)");
      verifyRequest(data);
    } catch (error) {
      console.log(error, " error in get user info");
      setIsLoading(false);
      setIsTokenExpired(true);
      return <>Not Authorised</>;
    }
  };
  const verifyRequest = async (data) => {
    let body = {
      userId: data?.userid,
      caseLineItemNumber: data?.caseid,
    };
    setEncodedInfo(data);
    setIsLoading(true);
    const result = await verifyRequestService(body);
    console.log(result, " in verify request");
    if (!result?.data?.data) {
      setIsTokenExpired(true);
      setIsLoading(false);
      return false;
    }
    const isMyTokenExpired = isExpired(data?.token);
    console.log(isMyTokenExpired, "isMyTokenExpired");
    setIsTokenExpired(isMyTokenExpired);
    // setIsTokenExpired(true);
    setIsLoading(false);

    if (data?.type === "Damage") {
      const result = await fetchAzureBearerToken();
      console.log(result, "result fetchAzureBearerToken");
      if (result?.data?.data) {
        setAzureToken(result?.data?.data);
      }
    }
    if (data?.type === "product") {
      console.log("in product type");
      generateOAuthToken();
    }
    return true;
  };

  useEffect(() => {
    if (!!encodedInfo?.type) {
      const URL = window.location.href.split("/");
      console.log("URL", window.location.href);
      if (
        URL.length > 0 &&
        URL[2] === "azfraeprdotbackoffice.azurewebsites.net" &&
        encodedInfo?.isSandBox
      ) {
      }
      window.history.replaceState(null, null, "ImageRecognition");
    }
  }, [encodedInfo]);
  useEffect(() => {
    if (!!encodedInfo?.type && isTokenExpired) {
      console.log("in !!encodedInfo?.type && isTokenExpired");
      console.log(
        !!encodedInfo?.type && isTokenExpired,
        "!!encodedInfo?.type && isTokenExpired"
      );
      handleCancel();
    }
  }, [encodedInfo, isTokenExpired]);

  const generateOAuthToken = async () => {
    console.log("in generateOAuthToken");
    const result = await getAuthToken();
    console.log(result.data, "oauth token");
    if (result.status === 200) {
      setAccessToken(result.data.data);
    }
  };
  const signatures = {
    JVBERi0: "pdf",
    R0lGODdh: "gif",
    R0lGODlh: "gif",
    iVBORw0KGgo: "png",
    "/9j/": "jpeg",
  };

  function detectMimeType(b64) {
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }
  const base64ToFormData = () => {
    fetch(image)
      .then((res) => res.blob())
      .then(async (blob) => {
        uploadImageToAzure(blob);
      });
  };

  const uploadImageToAzure = async (blob) => {
    setisButtonDisabled(true);
    setisButtonLoading(true);
    const fd = new FormData();
    console.log(AESencrypt(process.env.REACT_APP_SFDC_API_KEY));
    console.log(AESdecrypt("AESdecrypt"));
    const file = new File([blob], "filename.jpeg");
    fd.append("file", file);
    // fd.append("token", "xxpCVcrgYkEuGzGfITKYGXNm1flb")
    // try {
    //   const result = await uploadImageToAzureService(fd)
    //   console.log(result)
    // } catch (e) {
    //   console.log(e)
    // }
    axios
      .post(`${process.env.REACT_APP_SFDC_POINT}/blob/v1/upload`, fd, {
        headers: {
          "content-type": "multipart/form-data",
          apikey: process.env.REACT_APP_SFDC_API_KEY,
          Authorization: `Bearer ${azureToken}`,
        },
      })
      .then((res) => {
        handleDamageImage(res?.data?.UUID, false);
      })
      .catch((error) => console.log(error));
  };

  const submitImageToAI = async () => {
    console.log(getTime(new Date()), "confirm button clicked")
    console.time("total execution")
    setisButtonDisabled(true);
    setisButtonLoading(true);
    const base64Str = image.replace(/^data:image\/(png|jpeg);base64,/, "");
    const data = {
      base64str: base64Str,
      caseId: encodedInfo.caseid,
      userId: encodedInfo.userid,
      timestamp,
      token: accessToken,
      deviceType: isAndroid || isBrowser ? "Android" : "IOS",

      // token: "SXD8ni3rBGlNOzAGZwj3eFSi2aAU"
    };

    // now upload
    const config = {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
        // Authorization: "Bearer EanvEFwEtZPJcun8xgM3bmA7yrTZ",
        "Access-Control-Allow-Origin": "*",
        apikey: process.env.REACT_APP_API_KEY,
      },
    };
    try {
      console.log(getTime(new Date()), " api call")
      console.time("api call")
      const result = await sendImageToAI(data);
      console.log(result, "result");
      console.log(getTime(new Date()), " after API call")
      console.timeEnd("api call")

      if (result?.status == 200 && result?.data?.dot) {
        setDotRes(true);
        setDot(result?.data?.dot.toUpperCase());
        setisButtonDisabled(false);
        setisButtonLoading(false);
      } else {
        setDotRes(false);
        setisButtonLoading(false);
        setisButtonDisabled(false);
      }
    } catch (error) {
      setError(error.message);
    }
    console.timeEnd("total execution")

  };

  const handleDamageImage = async (id, isWindowClosed) => {
    let data = {};
    try {
      if (isWindowClosed) {
        data = {
          userId: encodedInfo?.userid,
          caseLineItemNumber: encodedInfo?.caseid,
          isWindowClosed: true,
        };
      } else {
        data = {
          userId: encodedInfo?.userid,
          caseLineItemNumber: encodedInfo?.caseid,
          azureId: id,
        };
      }

      const result = await addDamageImageData(data);
      if (result?.data) {
        if (!data?.isWindowClosed) {
          setImageResponse(true);
          setisButtonLoading(false);
          localStorage.clear();
          setTimeout(() => {
            window.close();
          }, "4000");
        } else {
          setisButtonLoading(false);
          localStorage.clear();
          window.close();
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const handleDotSidewallImage = async (isWindowClosed) => {
    setisButtonDisabled(true);
    setisButtonLoading(true);
    let data = {};
    try {
      if (isWindowClosed) {
        data = {
          userId: encodedInfo?.userid,
          caseLineItemNumber: encodedInfo?.caseid,
          isWindowClosed: true,
        };
      } else {
        const base64Str = image.replace(/^data:image\/(png|jpeg);base64,/, "");
        const type = detectMimeType(base64Str);
        data = {
          userId: encodedInfo?.userid,
          caseLineItemNumber: encodedInfo?.caseid,
          imageUrl: base64Str,
          imageType: type,
        };
      }

      console.log(data);
      const result = await addDotSidewallImageData(data);
      if (!data?.isWindowClosed) {
        setImageResponse(true);
        setisButtonLoading(false);
        localStorage.clear();
        setTimeout(() => {
          window.close();
        }, "4000");
      } else {
        setisButtonLoading(false);
        localStorage.clear();
        window.close();
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const handleDotDataForAndroid = async () => {
    setisButtonDisabled(true);
    setisButtonLoading(true);
    let data = {};
    try {
      const base64Str = image.replace(/^data:image\/(png|jpeg);base64,/, "");
      const type = detectMimeType(base64Str);

      data = {
        userId: encodedInfo?.userid,
        caseId: encodedInfo?.caseid,
        dot: dot,
        device: encodedInfo?.deviceType,
        imageUrl: base64Str,
        imageType: type,
      };

      const result = await addDotDataForAndroid(data);
      if (result?.data) {
        submitEditedDotToAI();
        // setisButtonLoading(false);
        // setImageResponse(true);
        // localStorage.clear();
        // window.close();
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const handleDotDataForIos = async () => {
    setisButtonDisabled(true);
    setisButtonLoading(true);
    const base64Str = image.replace(/^data:image\/(png|jpeg);base64,/, "");
    const type = detectMimeType(base64Str);
    try {
      const data = {
        userId: encodedInfo?.userid,
        caseId: encodedInfo?.caseid,
        dot: dot,
        imageUrl: base64Str,
        imageType: type,
      };
      const result = await addDotDataForIos(data);
      if (result?.data) {
        submitEditedDotToAI();
        // setisButtonLoading(false);
        // setImageResponse(true);
        // localStorage.clear();
        // window.close();
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const submitEditedDotToAI = async () => {
    console.log(getTime(new Date()), "edited dot before API call")
    const data = {
      dot,
      token: accessToken,
      // token: "SXD8ni3rBGlNOzAGZwj3eFSi2aAU",
      caseId: encodedInfo.caseid,
      userId: encodedInfo.userid,
      deviceType: isAndroid || isBrowser ? "Android" : "IOS",
      timestamp,
    };
    try {
      console.log(getTime(new Date()), "edited dot  API call")

      const result = await sendEditedDOT(data);
      console.log(result, "result sendEditedDOT");
      console.log(getTime(new Date()), "edited dot after API call")

      if (result?.status === 200 && result?.data?.submitted) {
        setisButtonLoading(false);
        setImageResponse(true);
        localStorage.clear();
        setTimeout(() => {
          window.close();
        }, "4000");
      } else {
        setisButtonLoading(false);
        setImageResponse(false);
        localStorage.clear();
        setTimeout(() => {
          window.close();
        }, "4000");
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const handleRetry = () => {
    setImage(null);
    setDisplayImage(null)
    setIsLoading(false);
    setisButtonDisabled(false);
    setisButtonLoading(false);
    setDot("");
    setDotRes(false);
    setOpenConfirmDialog(false);
    setError("");
  };
  const handleCancel = () => {
    console.log("in cancel");
    if ((encodedInfo?.type).toUpperCase() === "PRODUCT") {
      window.close();
    } else if ((encodedInfo?.type).toUpperCase() === "DAMAGE") {
      handleDamageImage(null, true);
    } else if (
      (encodedInfo?.type).toUpperCase() === "TIRE_SERIAL_NUMBER" ||
      "Tire_Identification"
    ) {
      handleDotSidewallImage(true);
    }
  };

  const AESencrypt = (text) => {
    const key = "secretKey123";
    const cipherText = CryptoJS.AES.encrypt(
      "e5QnJOPnRvHEG1vS1u0rS0AWF05Ev6rw",
      key
    ).toString();
    return cipherText;
  };
  const AESdecrypt = (text) => {
    const key = "secretKey123";
    const bytes = CryptoJS.AES.decrypt(
      "U2FsdGVkX186XxMWkR+0UJbKe2gx5ow1H2BHiIKpYzisWINUe2hCevRzDny1uwH7EHYfz1o2HSJQkiI/3gj2dw==",
      key
    );
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  };

  // useEffect(() => {
  //   let closeTimer = setTimeout(() => {
  //     // setisButtonDisabled(true);
  //     // handleCancel();

  //   }, 120000);
  //   return () => {
  //     clearTimeout(closeTimer);
  //   };
  // }, []);
  useEffect(() => {
    if (info === "ImageRecognition") {
      let info = JSON.parse(localStorage.getItem("info"));
      getUserInfo(info);
    }
  }, [info]);
  useEffect(() => {
    if (image) getImageDimensions();
  }, [image]);
  function getImageDimensions() {
    const img = new Image();

    img.src = image;

    img.onload = function () {
      let imgWidth = img.naturalWidth;
      let imgHeight = img.naturalHeight;
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      if (imgHeight < 670) {
        canvas.width = imgWidth * 2;
        canvas.height = imgHeight * 2;
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

        var dataURI = canvas.toDataURL();
        if (dataURI) {
          setImgWidth(imgWidth)
          setImgHeight(imgHeight)
          setImage(dataURI);
        }
      }
    };
  }
  if (info === "ImageRecognition") {
    return (
      <>
        <UnauthorisedPage />
      </>
    );
  }
  return (
    <>
      {isLoading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {isTokenExpired ? (
            <>
              <UnauthorisedPage />
            </>
          ) : (
            <>
              <>
                <div
                  style={window.innerWidth < window.innerHeight ? {
                    backgroundImage: `url(${background})`,
                    height: appHeight,
                    // backgroundColor: "black",
                    overflow: "hidden"
                  } : window.innerWidth > window.innerHeight ? {
                    backgroundImage: `url(${background})`,
                    // backgroundColor: "black",

                    height: appHeight,
                    overflow: "hidden"
                  } : {}}
                >
                  {encodedInfo?.type && (
                    <Header
                      type={encodedInfo?.type}
                      deviceType={encodedInfo?.deviceType}
                      handleCancel={handleCancel}
                      setisButtonDisabled={setisButtonDisabled}
                      setIsTimerUp={setIsTimerUp}
                    />
                  )}
                  {!isTimerUp && (
                    <>
                      {!image && encodedInfo?.type ? (
                        <WebCam
                          // canvasRef={canvas}
                          // camera={cameraRef}
                          setImage={setImage}
                          setTimestamp={setTimestamp}
                          setDisplayImage={setDisplayImage}
                          type={encodedInfo?.type}
                          deviceType={encodedInfo?.deviceType}
                          symbolname={encodedInfo?.symbolname}
                        />
                      ) : (
                        <>
                          <img
                            id="captured"
                            src={image}
                            alt="Image"
                            style={{
                              width: "100%",
                              height: "99vh",
                            }}
                          />
                          <>
                            {dotRes ? (
                              <EditDotModal
                                onChange={(e) => setDot(e)}
                                dot={dot}
                                handleDotDataForAndroid={
                                  handleDotDataForAndroid
                                }
                                isPortal={encodedInfo?.isPortal}
                                handleDotDataForIos={handleDotDataForIos}
                                isAndroidDevice={
                                  encodedInfo?.deviceType === "Android"
                                    ? true
                                    : false
                                }
                                handleRetry={handleRetry}
                                open={openConfirmDialog}
                                isButtonLoading={isButtonLoading}
                                isButtonDisabled={isButtonDisabled}
                                imageResponse={imageResponse}
                              />
                            ) : (
                              <>
                                <ConfirmModal
                                  // canvas={canvas}
                                  handleDamageImage={base64ToFormData}
                                  handleDotSidewallImage={
                                    handleDotSidewallImage
                                  }
                                  submitImageToAI={submitImageToAI}
                                  isAndroidDevice={
                                    encodedInfo?.deviceType === "Android"
                                      ? true
                                      : false
                                  }
                                  type={encodedInfo?.type}
                                  handleRetry={handleRetry}
                                  isButtonDisabled={isButtonDisabled}
                                  open={openConfirmDialog}
                                  image={image}
                                  width={imgWidth}
                                  height={imgHeight}
                                  displayImage={displayImage}
                                  isButtonLoading={isButtonLoading}
                                  imageResponse={imageResponse}
                                />
                              </>
                            )}
                          </>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            </>
          )}
        </>
      )}
    </>
  );
}
