import { Accelerometer } from "motion-sensors-polyfill";
import imageCompression from "browser-image-compression";
// import { fetchAzureBearerTokenService } from "../service/imageStorageService";

export const decrypt = (encoded) => {
  console.log("In decryption Method");
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(process.env.REACT_APP_SALT).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};
// export const fetchAzureBearerToken = async () => {
//   const data = new URLSearchParams();
//   data.append("username", process.env.REACT_APP_SFDC_USERNAME);
//   data.append("password", process.env.REACT_APP_SFDC_PASSWORD);
//   const result = await fetchAzureBearerTokenService(data, {
//     headers: {
//       "content-type": "application/x-www-form-urlencoded",
//       apikey: process.env.REACT_APP_SFDC_API_KEY,
//     },
//   });
//   return result;
// };
export const newRoundedRectWider = (ctx, width, height) => {
  ctx.beginPath();
  ctx.lineWidth = 5;
  ctx.strokeStyle = "red";
  ctx.moveTo(30, height / 4);
  ctx.arc(30, height / 4 - 30, 5, 0, 2 * Math.PI);
  ctx.stroke();
};

export const getFileSize = (dataUrl) => {
  // var base64String = dataUrl.split(",")[1];
  //  console.log("base64", base64String.length);

  // Decode the dataURL
  // var binary = atob(dataUrl.split(",")[1]);
  var binary = Buffer.from(dataUrl.split(",")[1], "base64").toString("binary");
  // Create 8-bit unsigned array
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Return our Blob object
  return new Blob([new Uint8Array(array)]).size;
};
export const roundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "red";
  ctx.moveTo(x, y + radius);

  ctx.lineTo(x, y + height - radius);
  ctx.arcTo(x, y + height, x + radius, y + height, radius);
  ctx.lineTo(x + width - radius, y + height);
  ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
  ctx.lineTo(x + width, y + radius);
  ctx.arcTo(x + width, y, x + width - radius, y, radius);
  ctx.lineTo(x + radius, y);
  ctx.arcTo(x, y, x, y + radius, radius);
  ctx.stroke();
  // ctx.moveTo(x+50, y+height-15);
  // ctx.lineTo(x+width-10, y+height-15);
  // ctx.stroke();
};

export const drawTextAlongArc = (
  context,
  str,
  centerX,
  centerY,
  radius,
  angle
) => {
  // context.clearRect(0, 0,  centerX,
  //   centerY,);
  context.save();
  context.font = "40px serif ";
  context.fillStyle = "white";
  context.translate(centerX, centerY);
  context.rotate((-1 * angle) / 2);
  context.rotate((-1 * (angle / str.length)) / 2);
  for (var n = 0; n < str.length; n++) {
    context.rotate(angle / str.length);
    context.save();
    context.translate(0, -1 * radius);
    var char = str[n];
    context.fillText(char, 0, 0);
    context.restore();
  }
  context.restore();
};
export const inverseGuidance = (ctx, width, height) => {
  ctx.beginPath();
  ctx.lineWidth = 5;
  ctx.strokeStyle = "yellow";
  ctx.setLineDash([10, 10]);

  ctx.moveTo(0, height / 4 + 60);
  // ctx.arc(width, height/4 +60, 5, 0, 2*Math.PI);
  ctx.quadraticCurveTo(width / 2, height / 4 - 50, width + 30, height / 4 + 70);
  ctx.moveTo(0, height / 2 + 70);
  ctx.quadraticCurveTo(width / 2, height / 2 - 50, width + 30, height / 2 + 70);

  ctx.stroke();
};

export const staticGuidance = (ctx, width, height) => {
  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "yellow";
  ctx.setLineDash([20, 10]);
  ctx.stroke();
  ctx.moveTo(0, height / 4 + 90);
  ctx.bezierCurveTo(20, 30, width, 30, width + 30, height / 4 + 90);
  //  ctx.bezierCurveTo(20, height / 6, width, height / 6, width, height / 4 + 60);
  ctx.stroke();
  ctx.moveTo(0, height / 1.5 + 100);
  ctx.bezierCurveTo(20, 150, width, 150, width + 30, height / 1.5 + 100);

  // ctx.bezierCurveTo(
  //   20,
  //   height / 1.5,
  //   width,
  //   height / 1.5,
  //   width,
  //   height / 1.5 + 60
  // );
  ctx.stroke();
  // ctx.stroke();
};
export const newRoundedRect = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  ctx.lineWidth = 5;
  ctx.strokeStyle = "yellow";
  let point = width / 7;
  let x1 = point;
  let y1 = y;
  let x2 = point;
  let y2 = height - 50;
  let length = y2 - y1;
  let breadth = length / 2.37;
  let x3 = x1 + breadth;
  let y3 = height - 50;
  let x4 = x1 + breadth;
  let y4 = y;

  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);

  // ctx.arc(x1, y1, 5, 0, 2 * Math.PI);
  // console.log(`x1:${x1}, y1: ${y1}`);
  // ctx.moveTo(x2, y2);
  ctx.lineTo(x3, y3);
  // ctx.arc(x2, y2, 5, 0, 2 * Math.PI);
  // console.log(`x2:${x2}, y2: ${y2}`);
  // ctx.moveTo(x3, y3);
  ctx.lineTo(x4, y4);
  // ctx.arc(x3, y3, 5, 0, 2 * Math.PI);
  // console.log(`x3:${x3}, y3: ${y3}`);
  // ctx.moveTo(x4, y4);
  ctx.lineTo(x1, y1);
  // ctx.arc(x4, y4, 5, 0, 2 * Math.PI);
  // console.log(`x4:${x4}, y4: ${y4}`);
  // console.log(`Length: ${length}, breadth: ${breadth}`);

  // ctx.moveTo(point + point * 4, height - 50);
  // ctx.arc(point + point * 4, height - 50, 5, 0, 2 * Math.PI);
  ctx.setLineDash([0, 0]);
  ctx.stroke();
};

export const Arc = (context, width, height) => {
  context.beginPath();

  // TO check the control point
  // context.moveTo(width / 7 + 200, height / 2 - 50);
  // context.arc(width / 7 + 200, height / 2 - 50, 5, 0, 2 * Math.PI);

  context.moveTo(width / 7, 80);
  context.quadraticCurveTo(width / 7 + 200, height / 2, width / 7, height - 60);
  context.setLineDash([20, 10]);
  context.strokeStyle = "white";
};
export const drawText = (ctx, width, height) => {
  ctx.stroke();
  ctx.save();
  ctx.font = "40px Arial ";
  ctx.fillStyle = "white";
  ctx.translate(width / 7 + 50, 120);
  ctx.rotate(Math.PI * 0.4);

  ctx.fillText("DOT", 0, 0);
  // ctx.fillRect(0, 0, 5, 5);

  ctx.restore();
};
export const newParallelLines = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  let point = width / 4;
  let x1 = point;
  let y1 = y - 50;
  let x2 = point;
  let y2 = height - 50;

  ctx.setLineDash([20, 10]);
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.lineWidth = 5;
  // set line color
  ctx.strokeStyle = "yellow";
  ctx.stroke();

  //second line
  //let distance = (y2 - y1) / 2.37;
  let x3 = point + point * 2;
  // if (x3 > width) {
  //   x3 = distance;
  // }

  let y3 = y - 50;
  let x4 = x3;
  let y4 = height - 50;
  ctx.beginPath();
  // ctx.moveTo(x + width - 50, y + radius);
  ctx.moveTo(x3, y3);
  ctx.lineTo(x4, y4);
  ctx.strokeStyle = "yellow";
  ctx.stroke();
};
export const parallelLines = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  let x1 = x + 20;
  let y1 = y;
  ctx.moveTo(x1, y + radius);
  ctx.lineTo(x1, y1);
  ctx.lineWidth = 5;
  // set line color
  ctx.strokeStyle = "yellow";
  ctx.stroke();

  //second line
  let x2 = x + width - 50;
  let y2 = y + height - radius;

  ctx.beginPath();
  ctx.moveTo(x2, y + radius);
  ctx.lineTo(x2, y2);
  ctx.strokeStyle = "yellow";
  ctx.stroke();
};

export const concentricCirles = (ctx, width, height) => {
  let centerX = width / 2;
  let centerY = height / 2;
  let radius = width / 2;
  if (width > height) {
    radius = height / 2;
  }
  ctx.lineWidth = 5;
  ctx.strokeStyle = "yellow";
  ctx.setLineDash([20, 10]);
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius - 8, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  ctx.arc(centerX, centerY, radius - 80, 0, 2 * Math.PI);
  ctx.stroke();
  ctx.closePath();
};

export const Capsule = (ctx, width) => {
  ctx.beginPath();
  ctx.setLineDash([0, 0]);
  let x = width / 2;

  ctx.moveTo(x - 25, 10);
  // ctx.arc(x - 25, 10, 4, 0, 2 * Math.PI);
  ctx.lineTo(x + 40, 10);

  // ctx.moveTo(x + 40, 10);
  // ctx.arc(x + 40, 10, 4, 0, 2 * Math.PI);

  // ctx.moveTo(x + 55, 20);
  // ctx.arc(x + 55, 20, 4, 0, 2 * Math.PI);
  ctx.quadraticCurveTo(x + 55, 20, x + 40, 30);
  // ctx.moveTo(x + 40, 30);
  // ctx.arc(x + 40, 30, 4, 0, 2 * Math.PI);

  // ctx.moveTo(x - 25, 30);
  // ctx.arc(x - 25, 30, 4, 0, 2 * Math.PI);
  ctx.lineTo(x - 25, 30);

  // ctx.moveTo(x - 35, 20);
  // ctx.arc(x - 35, 20, 4, 0, 2 * Math.PI);
  ctx.quadraticCurveTo(x - 40, 20, x - 25, 10);
  ctx.closePath();
  ctx.fillStyle = "#FF0000";
  ctx.strokeStyle = "#FF0000";

  ctx.fill();
  ctx.stroke();
};
export const Timer = (ctx, width) => {
  ctx.font = "15px Comic Sans MS";
  ctx.fillStyle = "white";
  ctx.textAlign = "center";
  ctx.fillText("00:02:00", width / 2 + 10, 25);
};

// generate a new file from dataURL String
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }
  return new File([u8arr], filename, { type: mime });
};

//detect motion of device
export const detectMotion = () => {
  let accelerometer = null;
  let prevX = null;
  let prevY = null;
  let prevZ = null;
  // if ('Accelerometer' in window) {
  try {
    accelerometer = new Accelerometer();
    accelerometer.start();
    accelerometer.onreading = (e) => {
      if (
        prevX !== accelerometer.x ||
        prevY !== accelerometer.y ||
        prevZ !== accelerometer.z
      ) {
        console.warn("Camera got moved!!!!!");
      }

      prevX = accelerometer.x;
      prevY = accelerometer.y;
      prevZ = accelerometer.z;
      console.log(accelerometer);
    };
    accelerometer.onerror = (event) => {
      // Handle runtime errors.
      if (event.error.name === "NotAllowedError") {
        console.log("Permission to access sensor was denied.");
      } else if (event.error.name === "NotReadableError") {
        console.log("Cannot connect to the sensor.", event.error);
      }
    };
  } catch (error) {
    // Handle construction errors.
    if (error.name === "SecurityError") {
      console.log("Sensor construction was blocked by the Permissions Policy.");
    } else if (error.name === "ReferenceError") {
      console.log("Sensor is not supported by the User Agent.");
    } else {
      throw error;
    }
  }
  // }
};

// change resolution option only for finalizing.
export const changeResolution = (resolution) => {
  console.log(resolution);
  let resl = {
    width: 1280,
    height: 720,
  };

  switch (resolution) {
    case "FHD":
      resl.width = 1920;
      resl.height = 1080;
      return resl;
    case "QHD":
      resl.width = 2560;
      resl.height = 1440;
      return resl;
    case "UHD":
      resl.width = 3840;
      resl.height = 2160;
      return resl;
    default:
      return resl;
  }
};

export const compressImage = async (file, maxWidth) => {
  console.log("input", file);

  var options = {
    maxSizeMB: 4,
    maxWidthOrHeight: maxWidth,
    useWebWorker: false,
    // onProgress: onProgress
  };
  try {
    const compressedImage = await imageCompression(file, options);
    console.log("output image", compressedImage);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(compressedImage);
    link.setAttribute("download", "dotValidateCompress");
    link.click();
    return compressedImage;
  } catch (error) {
    console.log("Compressing Error", error);
  }
};

export const getDateTime = (date) => {
  const d = `${date.getDate()}`;
  const month = `${date.getMonth() + 1}`;
  const year = date.getFullYear();
  const hours = `${date.getHours()}`;
  const minutes = `${date.getMinutes()}`;
  const seconds = `${date.getSeconds()}`;
  const milliseconds = `${date.getMilliseconds()}`;
  return `${d.length === 1 ? d.padStart(2, "0") : d}${month.length === 1 ? month.padStart(2, "0") : month
    }${year}${hours.length === 1 ? hours.padStart(2, "0") : hours}${minutes.length === 1 ? minutes.padStart(2, "0") : minutes
    }${seconds.length === 1 ? seconds.padStart(2, "0") : seconds}${milliseconds.length === 1 || milliseconds.length === 2
      ? milliseconds.padStart(3, "0")
      : milliseconds
    }_g`;
};


export const getTime = (date) => {

  const hours = `${date.getHours()}`;
  const minutes = `${date.getMinutes()}`;
  const seconds = `${date.getSeconds()}`;

  return `${hours.length === 1 ? hours.padStart(2, "0") : hours}${minutes.length === 1 ? minutes.padStart(2, "0") : minutes
    }${seconds.length === 1 ? seconds.padStart(2, "0") : seconds}`;
};