import httpService from "./httpService";
import { url } from "../constants/url";

const ADD_PDP_DATA = `${url}/pdp/addImage`;
const ADD_DAMAGE_IMAGE = `${url}/cli/damage-image`;
const ADD_DOT_SIDEWALL_IMAGE = `${url}/cli/dotsidewall-image`;
const VERIFY_REQUEST = `${url}/verify/request`;
export async function addPdpData(data, headers) {
  const result = await httpService.post(ADD_PDP_DATA, data, headers);
  return result;
}

export async function addDamageImageData(data) {
  const result = await httpService.post(ADD_DAMAGE_IMAGE, data);
  return result;
}

export async function addDotSidewallImageData(data) {
  const result = await httpService.post(ADD_DOT_SIDEWALL_IMAGE, data);
  return result;
}

export async function verifyRequestService(data) {
  const result = await httpService.post(VERIFY_REQUEST, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  return result;
}
