import { height, width } from "@mui/system";
import GUIDANCE from "../constants/guidance";

export const drawGuidance = (ctx, symbolname, type, deviceType) => {
  try {
    let video = document.querySelector("video");
    let computeStyles = window.getComputedStyle(video);
    let width = computeStyles.getPropertyValue("width").slice(0, -2);
    let height = computeStyles.getPropertyValue("height").slice(0, -2);
    console.log(type.toUpperCase() === "TIRE_SERIAL_NUMBER" && symbolname.toUpperCase() === GUIDANCE.SIDEWALL
      , type.toUpperCase())
    if (type.toUpperCase() === "TIRE_SERIAL_NUMBER" && symbolname.toUpperCase() === GUIDANCE.SIDEWALL) {
      console.log("in if")
      if (window.innerWidth > window.innerHeight) {
        if (deviceType.toUpperCase() === "ANDROID") {
          ctx.canvas.width = parseFloat(width) - 88
          ctx.canvas.height = parseFloat(height);
        } else {
          ctx.canvas.width = parseFloat(width) - 85;
          ctx.canvas.height = parseFloat(height) - 10;
        }
      } else {
        ctx.canvas.width = parseFloat(width);
        ctx.canvas.height = parseFloat(height) - 85;
      }


    } else if (GUIDANCE.PRODUCT === type.toUpperCase() || type === "Tire_Identification" || type.toUpperCase() === "TIRE_SERIAL_NUMBER") {

      console.log("in else if")
      if (window.innerWidth > window.innerHeight) {
        if (deviceType.toUpperCase() === "ANDROID") {
          ctx.canvas.width = parseFloat(width) - 88
          ctx.canvas.height = parseFloat(height);
        } else {
          ctx.canvas.width = parseFloat(width) - 90;
          ctx.canvas.height = parseFloat(height) + 5;
        }
      } else {
        ctx.canvas.width = parseFloat(width) + 5;
        ctx.canvas.height = parseFloat(height);
      }

    } else {
      console.log("in else")
      if (window.innerWidth > window.innerHeight) {
        if (deviceType.toUpperCase() === "ANDROID") {
          ctx.canvas.width = parseFloat(width) - 88
          ctx.canvas.height = parseFloat(height);
        } else {
          ctx.canvas.width = parseFloat(width) - 85;
          ctx.canvas.height = parseFloat(height) - 10;
        }
      } else {
        ctx.canvas.width = parseFloat(width);
        ctx.canvas.height = parseFloat(height) - 80;
      }

    }
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    if (GUIDANCE.SIDEWALL === symbolname.toUpperCase()) {
      concentricCirles(ctx, width, height);
    }
    else if (GUIDANCE.RECTANGLE === symbolname.toUpperCase() || type === "Tire_Identification" || type.toUpperCase() === "TIRE_SERIAL_NUMBER") {
      drawRect(ctx, ctx.canvas.width, ctx.canvas.height, type);
      if (type.toUpperCase() === GUIDANCE.PRODUCT) {
        drawText(ctx, ctx.canvas.width, ctx.canvas.height, type);
        Arc(ctx, ctx.canvas.width, ctx.canvas.height, type);
      }
      if (type.toUpperCase() !== "TIRE_SERIAL_NUMBER") {

        // Arc(ctx, ctx.canvas.width, ctx.canvas.height, type);
      }
    } else if (GUIDANCE.INVERSE === symbolname.toUpperCase()) {
      inverseGuidance(ctx, width, height);
    } else if (GUIDANCE.PARALLEL === symbolname.toUpperCase()) {
      ParallelLines(ctx, 30, 80, width, height, 7);
    }
    // else 
    // if (GUIDANCE.SIDEWALL === symbolname.toUpperCase()) {
    //   concentricCirles(ctx, width, height);
    //   // inverseGuidance(ctx, ctx.canvas.width, ctx.canvas.height);
    //   // ParallelLines(ctx, 30, 80, width, height, 7);





    // }
  } catch (error) { }
};

const concentricCirles = (ctx, width, height) => {
  let centerX = width / 2;
  let centerY = height / 2 - 25;
  let radius = width / 2;
  if (parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    centerX = width / 2 - 44;
    centerY = height / 2 - 35
    radius = height / 2 - 100;


  }
  ctx.lineWidth = 5;
  ctx.strokeStyle = "yellow";
  ctx.setLineDash([20, 10]);
  ctx.beginPath();

  if (parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    if (parseFloat(window.innerWidth) >= 900) {
      ctx.arc(centerX, centerY, radius + 39, 0, 2 * Math.PI);

    }
    else {
      ctx.arc(centerX, centerY, radius + 45, 0, 2 * Math.PI);
    }

  } else {
    ctx.arc(centerX, centerY, radius - 8, 0, 2 * Math.PI);

  }


  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  if (parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    if (parseFloat(window.innerWidth) >= 900) {
      ctx.arc(centerX, centerY, radius - 70, 0, 2 * Math.PI);

    }
    else {
      ctx.arc(centerX, centerY, radius - 20, 0, 2 * Math.PI);
    }
  } else {
    if (parseFloat(window.innerWidth) >= 700) {
      ctx.arc(centerX, centerY, radius - 130, 0, 2 * Math.PI);

    } else {

      ctx.arc(centerX, centerY, radius - 80, 0, 2 * Math.PI);
    }
  }
  ctx.stroke();
  ctx.closePath();
};
const inverseGuidance = (ctx, width, height) => {
  ctx.beginPath();
  ctx.lineWidth = 5;
  ctx.strokeStyle = "yellow";
  ctx.setLineDash([10, 10]);

  if (window.innerWidth > window.innerHeight) {
    if (window.innerWidth >= 900) {
      ctx.moveTo(20, height / 3);
      // ctx.moveTo(width , height / 4 + 60);
      // ctx.arc(width , height / 4 + 60, 5, 0, 2*Math.PI);
      ctx.quadraticCurveTo(width / 2, height / 6 - 120, width - 15, height / 3);
      ctx.moveTo(0, height / 1);
      ctx.quadraticCurveTo(width / 2, height / 2 - 120, width - 10, height / 1);
    }
    else {

      ctx.moveTo(0, height / 20 + 100);
      // ctx.moveTo(width , height / 4 + 60);
      // ctx.arc(width , height / 4 + 60, 5, 0, 2*Math.PI);
      ctx.quadraticCurveTo(width / 2, height / 4 - 80, width - 20, height / 20 + 100);
      ctx.moveTo(0, height / 2 + 100);
      ctx.quadraticCurveTo(width / 2, height / 2 - 80, width - 15, height / 2 + 100);
    }

  } else {
    if (window.innerWidth >= 700) {
      ctx.moveTo(0, height / 4 + 200);
      // ctx.moveTo(width , height / 4 + 60);
      // ctx.arc(width , height / 4 + 60, 5, 0, 2*Math.PI);
      ctx.quadraticCurveTo(width / 2, height / 4 - 50, width, height / 4 + 200);
      ctx.moveTo(0, height / 2 + 200);
      ctx.quadraticCurveTo(width / 2, height / 2 - 50, width, height / 2 + 200);
    } else {

      ctx.moveTo(0, height / 4 + 60);
      // ctx.moveTo(width , height / 4 + 60);
      // ctx.arc(width , height / 4 + 60, 5, 0, 2*Math.PI);
      ctx.quadraticCurveTo(width / 2, height / 4 - 50, width, height / 4 + 60);
      ctx.moveTo(0, height / 2 + 70);
      ctx.quadraticCurveTo(width / 2, height / 2 - 50, width, height / 2 + 70);
    }

  }
  ctx.stroke();
};
const ParallelLines = (ctx, x, y, width, height, radius) => {
  ctx.beginPath();
  let point = width / 4;

  let x1 = point - 10;
  let y1 = y - 50;
  let x2 = x1;
  let y2 = height - 50;
  ctx.setLineDash([20, 10]);
  if (parseFloat(window.innerWidth) < 900 && parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    point = width / 8
    x1 = point + 180 - 24
    x2 = x1
    y1 = y - 70
    y2 = height - 20 - 40
  } else if (parseFloat(window.innerWidth) >= 900 && parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    point = width / 6
    x1 = point + 180 + 44
    x2 = x1
    y1 = y - 70
    y2 = height - 20 - 40
  } else {
    point = width / 4
    x1 = point - 10;
    y1 = y - 50;
    x2 = x1;
    y2 = height - 50 - 108;
  }
  console.log(x1, y1, x2, y2)
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.lineWidth = 5;
  // set line color
  ctx.strokeStyle = "yellow";
  ctx.stroke();

  //second line
  //let distance = (y2 - y1) / 2.37;

  let x3 = point + point * 2 + 10;

  let y3 = y - 50;
  let x4 = x3;
  let y4 = height - 50;
  if (parseFloat(window.innerWidth) < 900 && parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    x3 = point + point * 2 + 180 - 44;
    y3 = y - 70;
    x4 = x3;
    y4 = height - 20 - 40;
  } else if (parseFloat(window.innerWidth) >= 900 && parseFloat(window.innerWidth) > parseFloat(window.innerHeight)) {
    x3 = point + point * 2 + 180 - 84;
    y3 = y - 70;
    x4 = x3;
    y4 = height - 20 - 40;
  } else {
    x3 = point + point * 2 + 10;
    y3 = y - 50;
    x4 = x3;
    y4 = height - 50 - 108;
  }
  console.log(x3, y3, x4, y4)
  ctx.beginPath();
  // ctx.moveTo(x + width - 50, y + radius);
  ctx.moveTo(x3, y3);
  ctx.lineTo(x4, y4);
  ctx.strokeStyle = "yellow";
  ctx.stroke();
};
const drawRect = (ctx, width, height, type) => {
  ctx.beginPath();
  if (GUIDANCE.PRODUCT === type.toUpperCase()) {
    if (window.innerWidth > window.innerHeight) {

      ctx.rect(10, 10, width - 20, height - 130);
    } else {
      ctx.rect(10, 10, width - 25, height - 25);

    }
  }
  else if (type.toUpperCase() === "TIRE_SERIAL_NUMBER" || type === "Tire_Identification") {
    if (window.innerWidth > window.innerHeight) {
      ctx.rect(10, 10, width - 20, height - 130)

    } else {

      ctx.rect(10, 10, width - 25, height - 20)
    }
  }

  else {
    if (window.innerWidth > window.innerHeight) {
      ctx.rect(15, 60, width - 30, height - 40)

    } else {
      ctx.rect(15, 60, width - 30, height - 130)
    }
  }
  ctx.strokeStyle = "yellow";
  ctx.stroke();
};
const Arc = (ctx, width, height, type) => {
  ctx.beginPath();
  ctx.lineWidth = 4;
  ctx.moveTo(20, height - 19);
  // ctx.arc(20, height-30, 5, 0, 2 * Math.PI);
  if (GUIDANCE.PRODUCT === type.toUpperCase()) {
    if (window.innerWidth > window.innerHeight) {
      ctx.moveTo(20, height - 130);

      ctx.quadraticCurveTo(width / 2, height / 2 - 130, width - 30, height - 130);

      // ctx.quadraticCurveTo(width / 2, height / 2 - 90, width - 30, height - 90);

    } else {

      ctx.quadraticCurveTo(width / 2, height / 2 - 30, width - 30, height - 19);
    }
  } else if (window.innerWidth > window.innerHeight) {
    ctx.quadraticCurveTo(width / 2, height / 2 - 90, width - 30, height - 90);
  } else {
    ctx.quadraticCurveTo(width / 2, height / 2 + 150, width - 30, height - 30);
  }
  ctx.setLineDash([20, 10]);
  ctx.strokeStyle = "white";
  ctx.stroke();
};
const drawText = (ctx, width, height, type) => {
  ctx.stroke();
  ctx.save();
  ctx.font = "20px Arial ";
  ctx.fillStyle = "white";
  if (window.innerWidth > window.innerHeight) {
    ctx.translate(20, height - 140);

  } else {

    ctx.translate(20, height - 35);
  }
  if (GUIDANCE.PRODUCT === type.toUpperCase()) {

    ctx.rotate(Math.PI * -0.1);
  } else {
    ctx.rotate(Math.PI * -0.15);
  }

  ctx.fillText("DOT", 0, 0);
  // ctx.fillRect(0, 0, 5, 5);

  ctx.restore();
};
