import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuth } from "./custom hooks/useAuth";
import './App.css'
import WebCam from "./components/WebCam";
import DotGuidanceApp from "./components/DotGuidanceApp";
import UnauthorisedPage from "./components/UnauthorisedPage";
function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/:info">
            <DotGuidanceApp />
          </Route>
          <Route path="/">
            <UnauthorisedPage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
