import React, { useRef } from "react";

const CountDownTimer = ({
  hoursMinSecs,
  setisButtonDisabled,
  setIsTimerUp,
  isLandscape
}) => {
  const { hours = 0, minutes = 2, seconds = 0 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = React.useState([
    hours,
    minutes,
    seconds,
  ]);
  const intervalRef = useRef(null);
  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) reset();
    else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  const reset = () => {
    console.log("RESET");
    setisButtonDisabled(true);
    setIsTimerUp(true);
    setTime([parseInt(0), parseInt(0), parseInt(0)]);
    clearInterval(intervalRef.current);
    console.log(intervalRef.current)
    intervalRef.current = null

    setTimeout(() => {
      window.close();
    }, "4000");
  };

  React.useEffect(() => {
    intervalRef.current = setInterval(() => tick(), 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [secs]);
  return (
    <div
      style={isLandscape ? {
        margin: "auto",
        backgroundColor: mins === 0 && secs <= 5 ? "red" : "green",
        color: "white",
        fontSize: "larger",
        borderRadius: "25px",
        width: "80px",
        textAlign: "center",
        marginTop: "0px"
      } : {
        margin: "auto",
        marginTop: "auto",
        backgroundColor: mins === 0 && secs <= 5 ? "red" : "green",
        color: "white",
        fontSize: "larger",
        borderRadius: "25px",
        width: "80px",
        textAlign: "center",
      }}
    >{`${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</div>
  );
};

export default CountDownTimer;
