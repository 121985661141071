import httpService from "./httpService";
import { url } from "../constants/url";

const ADD_DOT_DATA_ANDROID = `${url}/product/image`;
const ADD_DOT_DATA_IOS = `${url}/product/frontend-data`;
const GET_AUTH_TOKEN = `${url}/get/auth-token`;
const SEND_IMAGE_TO_AI = `${url}/product/upload/dot-image`;
const SEND_EDITED_DOT = `${url}/product/edited-dot`;
export async function addDotDataForAndroid(data) {
  const result = await httpService.post(ADD_DOT_DATA_ANDROID, data);
  console.log(result);
  return result;
}
export async function addDotDataForIos(data) {
  const result = await httpService.post(ADD_DOT_DATA_IOS, data);
  return result;
}
export async function getAuthToken() {
  const result = await httpService.get(GET_AUTH_TOKEN);
  return result;
}

export async function sendImageToAI(data) {
  const result = await httpService.post(SEND_IMAGE_TO_AI, data);
  return result;
}

export async function sendEditedDOT(data) {
  const result = await httpService.post(SEND_EDITED_DOT, data);
  return result;
}
