import { TextField } from "@material-ui/core";
import { Button, CircularProgress, useMediaQuery } from "@mui/material";
import { useStyles } from "./AppStyle";
import MuiDialog from "./common/MuiDialog";
import confirm_btn from "../assets/confirm_btn.png";
import retake_btn from "../assets/retake_btn.png";
import { useState } from "react";
import LinearProgressWithValueLabel from "./LinearProgressBar";
const EditDotModal = (props) => {
  const classes = useStyles();
  const { open, handleRetry, isButtonLoading, imageResponse } = props;

  const [error, setError] = useState(false);
  const isLandscape = useMediaQuery('(orientation: landscape)')
  console.log(props.dot, "in edit modal");
  console.log(props?.dot && !props.isPortal, "props?.dot && !props.isPortal");
  return (
    <>
      <MuiDialog open={true} title="DOT or Tire ID" isLandscape={isLandscape} fromEdit={true} >
        <TextField

          style={{ alignItem: "center" }}
          className={classes.rootWithError}
          type="text"
          fullWidth
          variant="outlined"
          // inputProps={{ maxLength: 13, minLength: 11 }}
          value={props.dot}
          onChange={(e) => {
            props.onChange(e.target.value.toUpperCase());
          }}
          error={
            props?.dot &&
              props?.isPortal &&
              (props?.dot?.length === 11 ||
                props?.dot?.length === 12 ||
                props?.dot?.length === 13)
              ? false
              : props?.dot && !props?.isPortal
                ? false
                : true
          }
        />

        <div>
          <>
            {isButtonLoading && !imageResponse && (
              <LinearProgressWithValueLabel isLandscape={isLandscape}
                progValue={0}
              ></LinearProgressWithValueLabel>
            )}

            {imageResponse && !isButtonLoading && (
              <LinearProgressWithValueLabel isLandscape={isLandscape}
                progValue={100}
              ></LinearProgressWithValueLabel>
            )}
            <div
              style={isLandscape ? {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "1%"
              } : {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                className={classes.retryButton}
                color="primary"
                variant="outlined"
                onClick={handleRetry}
                disabled={props.isButtonDisabled}
              >
                <img src={retake_btn} alt="Retry" />
              </Button>
              {props?.isPortal && !props.isAndroidDevice ? (
                <>
                  <Button
                    variant="contained"
                    color={imageResponse ? "success" : "primary"}
                    sx={
                      !imageResponse &&
                        (props?.dot?.length < 11 || props?.dot.length > 13)
                        ? {
                          pointerEvents: "none",
                        }
                        : // : imageResponse && !props?.isPortal
                        // ? {
                        //     pointerEvents: "none",
                        //   }
                        { pointerEvents: "auto" }
                    }
                    disabled={
                      props.isButtonDisabled
                        ? !imageResponse && props.isButtonDisabled
                        : !imageResponse &&
                        props?.isPortal &&
                        (props?.dot?.length < 11 || props?.dot.length > 13)
                    }
                    className={classes.confirmButton}
                    onClick={() => {
                      if (props.dot) {
                        props.handleDotDataForIos(false);
                      } else {
                        setError(true);
                      }
                    }}
                  >
                    {isButtonLoading && <CircularProgress size={14} />}
                    {!isButtonLoading && (
                      <img src={confirm_btn} alt="Confirm" />
                    )}
                  </Button>
                </>
              ) : props?.isAndroidDevice ? (
                <>
                  <Button
                    variant="contained"
                    color={imageResponse ? "success" : "primary"}
                    sx={
                      imageResponse
                        ? // &&
                        // (props?.dot?.length < 11 || props?.dot.length > 13)
                        {
                          pointerEvents: "none",
                        }
                        : { pointerEvents: "auto" }
                    }
                    disabled={
                      // props.isButtonDisabled
                      // ?
                      !imageResponse && props.isButtonDisabled
                      // : !imageResponse &&
                      //   (props?.dot?.length < 11 || props?.dot.length > 13)
                      // !imageResponse
                      //   // ? !imageResponse && props.isButtonDisabled
                      //    props.dot.length < 11 || props?.dot?.length < 14
                    }
                    className={classes.confirmButton}
                    onClick={() => {
                      if (props.dot) {
                        props.handleDotDataForAndroid(false);
                      } else {
                        setError(true);
                      }
                    }}
                  >
                    {isButtonLoading && <CircularProgress size={14} />}
                    {!isButtonLoading && (
                      <img src={confirm_btn} alt="Confirm" />
                    )}
                  </Button>
                </>
              ) : (
                <>
                  {" "}
                  <Button
                    variant="contained"
                    color={imageResponse ? "success" : "primary"}
                    sx={
                      imageResponse
                        ? {
                          pointerEvents: "none",
                        }
                        : { pointerEvents: "auto" }
                    }
                    disabled={!imageResponse && props.isButtonDisabled}
                    className={classes.confirmButton}
                    onClick={() => {
                      if (props.dot) {
                        props.handleDotDataForIos();
                      } else {
                        setError(true);
                      }
                    }}
                  >
                    {isButtonLoading && <CircularProgress size={14} />}
                    {!isButtonLoading && (
                      <img src={confirm_btn} alt="Confirm" />
                    )}
                  </Button>
                </>
              )}
            </div>
          </>
        </div>
      </MuiDialog>
    </>
  );
};

export default EditDotModal;
