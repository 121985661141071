import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

export default function MuiDialog({ title, open, children, isLandscape, fromEdit }) {
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (reason === "escapeKeyDown") {
      return false;
    }

    // if (typeof onClose === "function") {
    //   onClose();
    // }
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
        <DialogContent style={isLandscape && fromEdit ? { overflow: "hidden", paddingTop: "1%" } : isLandscape ? { overflow: "hidden", paddingTop: 0 } : { overflow: "hidden" }}>{children}</DialogContent>
      </Dialog>
    </>
  );
}
