import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: "center",
    "& .Mui-error": {
      border: "2px solid #f44336 !important",
    },
  },
  error: {
    color: "red",
  },
  text: {
    color: "gray",
    fontSize: "18px",
  },
  inputText: {
    marginLeft: "7px",
  },
  circleButton: {
    width: "40px",
    height: "60px",
    borderRadius: "50%",
    background: "#28509b",
    border: "2px solid #fff",
  },
  circleButtonLandscape: {
    width: "40px",
    height: "60px",
    borderRadius: "50%",
    background: "#28509b",
    border: "2px solid #fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  // connectAI csss
  retryButton: {
    padding: "10px",
    margin: "10px 24px 0 0px",
    objectFit: "contain",
  },
  confirmButton: {
    padding: "10px",
    margin: "10px 24px 0 0px",
    objectFit: "contain",

  },


  SideWallAndSerial: {
    textTransform: "capitalize",
    margin: "5px",
    //padding: '3px 37px 3px 38px'
  },
  closeIconStyle: {
    justifyContent: "end",
  },
  cameraButton: {
    background: "#000",
    padding: "10px",
    marginBottom: "3px",

    position: "fixed",
    // left: "50%",
    right: "0",
    bottom: "0",
    // float: "right",
    width: "100%",
    /* color: white; */
    textAlign: "center",

  },
  cameraButtonLandscape: {
    background: "#000",
    padding: "12px",
    position: "fixed",
    top: "0",
    right: "0",
    transform: "rotate (-90deg)",
    // width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",


  },
  // closeIcon
  closeIconCss: {
    color: "#fff",
    // margin: '25px 0px 0 10px',
    width: "30px",
    marginLeft: "3px",
    objectFit: "contain",
  },
  // backgroundColor
  backgroundColorBody: {
    background: "rgba(0, 0, 0, 0.85)",
    margin: "0 auto",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: "0px",
    left: "0px",
  },
  // Rectangle yellow
  rectangle: {
    width: "281px",
    height: "115px",
    border: "solid 3px #fce500",
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  // triePart Image
  tirePart: {
    width: "332px",
    height: "133px",
    //margin: '11px 0 0',
    objectFit: "contain",
    position: "relative",
    right: "22px",
    top: "10px;",
  },
  // image curve shap yellow
  imgPath: {
    top: "43px",
    left: "83px",
    position: "absolute",
    objectFit: "contain",
  },

  // get started button
  startButton: {
    // fontFamily: 'NotoSans',
    width: "40px",
    height: "40px",
    margin: "200px 0 0 108px",
    //padding: '7px 41px 7px 42px',
    //borderRadius: '2px',
    backgroundColor: "#28509b",
    textTransform: "capitalize",

    //letterSpacing: '1px',
  },
}));

// export const ColorButton = withStyles((theme) => ({
//   root: {
//     // color: 'white',
//     // backgroundColor: "#027DB4",
//     // margin: "10px",
//     // "&:hover": {
//     //   color: "#027DB4",
//     // },
//   },
// }))(Button);
