import React from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CountDownTimer from "./CountDownTimer";
import { useStyles } from "./AppStyle";
export default function Header({ type, deviceType, handleCancel, setisButtonDisabled, setIsTimerUp }) {
  const hoursMinSecs = { hours: 0, minutes: 1, seconds: 52 };
  const isLandscape = useMediaQuery('(orientation: landscape)')
  const classes = useStyles();

  return (
    <>
      <div
        style={
          isLandscape && type === "product" || isLandscape && type === "Tire_Identification" || isLandscape && type.toUpperCase() === "TIRE_SERIAL_NUMBER"
            ? {
              display: "flex",
              width: deviceType.toUpperCase() === "ANDROID" ? "calc(100% - 137px)" : "calc(100% - 88px)",
              top: "0px",
              marginBottom: "3px"
            }

            : isLandscape && type
              !== "product" || isLandscape && type !== "Tire_Identification" || isLandscape && type.toUpperCase() !== "TIRE_SERIAL_NUMBER"
              ? {
                display: "flex",
                width: deviceType.toUpperCase() === "ANDROID" ? "calc(100% - 137px)" : "calc(100% - 88px)",
                position: "absolute",
                zIndex: "999",
                top: "0px",
              } : !isLandscape && type === "product" || !isLandscape && type === "Tire_Identification" || !isLandscape && type.toUpperCase() === "TIRE_SERIAL_NUMBER" ? {
                display: "flex",
                width: deviceType.toUpperCase() === "ANDROID" ? "calc(100% - 49px)" : "100%",
                top: "12px",
                marginBottom: "3px"

              } : {
                display: "flex",
                width: deviceType.toUpperCase() === "ANDROID" ? "calc(100% - 49px)" : "100%",
                position: "absolute",
                zIndex: "999",
                top: "12px",
              }
        }
      >
        {deviceType.toUpperCase() === "ANDROID" ? (
          <IconButton onClick={handleCancel} style={isLandscape ? { paddingTop: 0 } : {}}>
            <CloseIcon className={classes.closeIconCss} />
          </IconButton>
        ) : (
          <></>
        )}
        {/* <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton> */}
        <CountDownTimer hoursMinSecs={hoursMinSecs} isLandscape={isLandscape} setisButtonDisabled={setisButtonDisabled} setIsTimerUp={setIsTimerUp} />
      </div>
    </>
  );
}
