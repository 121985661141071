import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function MuiLinearProgressBar(props) {
  return (
    <Box sx={{ alignItems: "center" }}>
      <Box sx={props.isLandscape ?
        { minWidth: 35, textAlign: "right", margin: "0 0 0", padding: "0 0 0" } : { minWidth: 35, textAlign: "right", margin: "8px 0 4px", padding: "4px 0 0" }}>
        <Typography
          variant="body2"
          sx={{ color: "#27509b", fontWeight: "600" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%", mr: 1, color: "#27509b" }}>
        <LinearProgress
          color="inherit"
          variant="determinate"
          {...props}
        />
      </Box>
    </Box >
  );
}
