import httpService from "./httpService";
import { url, azure_image_storage_endpoint } from "../constants/url";

// const GET_TOKEN = `${azure_image_storage_endpoint}/token`;
const GET_TOKEN = `${url}/get/azure-token`;
// const UPLOAD_IMAGE = `${azure_image_storage_endpoint}/blob/v1/upload`;
const UPLOAD_IMAGE = `${url}/cli/upload/damage-image`;
export async function fetchAzureBearerToken() {
  const result = await httpService.get(GET_TOKEN);
  return result;
}
export async function uploadImageToAzureService(data) {
  const result = await httpService.post(UPLOAD_IMAGE, data);
  return result;
}
