import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Camera } from "react-camera-pro";
import GUIDANCE from "../constants/guidance";
import { drawGuidance } from "../utility/guidances";
import { getDateTime } from "../utility/utilityFunc";
import ClickImageButton from "./ClickImageButton";
import "./web-cam.css";
const WebCam = (props) => {
  const camera = useRef(null);
  const canvasRef = useRef(null);
  const { setImage, type, symbolname, deviceType } = props;
  useLayoutEffect(() => {
    function updateSize() {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      drawGuidance(ctx, symbolname, type, deviceType);
    }
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    drawGuidance(ctx, symbolname, type, deviceType);
  }, []);
  const takePhoto = () => {
    let noOfCamera = camera.current.getNumberOfCameras();
    if (noOfCamera > 0) {
      setImage(camera.current.takePhoto());
      props.setTimestamp(getDateTime(new Date()))
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        className={
          type.toUpperCase() === "TIRE_SERIAL_NUMBER" && GUIDANCE.SIDEWALL === symbolname.toUpperCase() ? "webcam-sidewall" :
            GUIDANCE.PRODUCT === type.toUpperCase() || type === "Tire_Identification" || type.toUpperCase() === "TIRE_SERIAL_NUMBER" && GUIDANCE.SIDEWALL !== symbolname.toUpperCase() ? "webcam" : "webcam-canvas"
        }
      >
        <Camera ref={camera} facingMode="environment" aspectRatio={16 / 6.75} />
        <ClickImageButton onClick={takePhoto} />
      </div>
      <canvas
        ref={canvasRef}
        id="myCanvas"
        style={{
          position: "absolute",
          top: 0,
        }}
      />
    </div>
  );
};

export default WebCam;
